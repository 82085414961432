// pages/404.js
import Link from 'next/link';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      router.push('/');
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [router]);

  return (
    <section className="section not-found">
      <div className="container-default w-container">
        <div className="not-found-content w-form">
          <div className="_404-not-found">404</div>
          <h1 className="display-1 text-medium color-neutral-100 mg-bottom-24px">Page not found</h1>
          <p className="color-neutral-300 mg-bottom-40px">
            Buy a Verified Mark Certificate (VMC) to add your logo & a blue checkmark to emails you send. Build instant trust with your email recipients by letting them know it&apos;s really you.
          </p>
          <div className="buttons-row center">
            <Link href="/" className="btn-primary w-button">
              Back home<span className="line-rounded-icon link-icon-right"></span>
            </Link>
          </div>
        </div>
      </div>
      <div className="position-absolute dark-fade---button not-found"></div>
    </section>
  );
}
